<div class="panel-modal modal-container-otp container-fluid">
	<div class="container-fluid">
		<fa-icon [icon]="faXmark" aria-hidden="true" class="icon-close" (click)="closeOtpModal()"></fa-icon>
		<div class="row align-items-center justify-content-evenly px-2" style="min-height: 55vh">
			<div class="text-center col-12">
				<h5 class="modal-title">
					<b>{{ "CREATE_USER.OTP_MODAL_TITLE" | translate }}</b>
				</h5>
				<p>{{ "CREATE_USER.OTP_MODAL_BODY" | translate }}</p>
			</div>

			<div class="col-12">
				<ng-otp-input class="text-center" #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="{ length:6, allowNumbersOnly: true }"></ng-otp-input>

				<p class="error-message" [hidden]="!otpCodeError">{{ "CREATE_USER.INVALID_OTP_CODE" | translate }}</p>

				<p class="text-center mt-3" *ngIf="!session.isProduction()">{{ "CREATE_USER.OTP_MODAL_CODE" | translate }}</p>

				<p *ngIf="!isOptician" class="text-center mt-3">{{ "CREATE_USER.OTP_MODAL_TEXT" | translate }}</p>
			</div>

			<div class="d-flex col-12 button-container">
				<div class="form-group">
					<button (click)="onOtpSubmit()" type="button" class="btn btn-danger d-flex" [disabled]="otpCode.length !== 6 || isSendingOtpCode">
						<span *ngIf="!isSendingOtpCode">{{ "BUTTONS.SUBMIT" | translate }}</span>
						<div class="spinner-border spinner-border-sm" role="status" *ngIf="isSendingOtpCode">
							<span class="sr-only">Loading...</span>
						</div>
					</button>
				</div>

				<div class="form-group">
					<button (click)="onOtpResendClick()" type="button" class="btn btn-secondary d-flex justify-content-center align-items-center" [disabled]="isReSendingOtpCode || isSendingOtpCode || otpResendDisabled">
						<span *ngIf="!isReSendingOtpCode">{{ "BUTTONS.RESEND_OTP_CODE" | translate }}</span>
						<span [hidden]="!otpResendDisabled || isReSendingOtpCode"> &nbsp;(<cd-timer #timer [countdown]="true" [startTime]="otpExpiringInSec" (onComplete)="onCompleteOtpTimer()" [format]="'ms'"></cd-timer>) </span>
						<div class="spinner-border spinner-border-sm" role="status" *ngIf="isReSendingOtpCode">
							<span class="sr-only">Loading...</span>
						</div>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
