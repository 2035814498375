<form role="form" #loginForm="ngForm" name="lForm" (ngSubmit)="login(loginForm)" novalidate>
	<div class="my-4">
		<p>{{ "LOGIN.INTRO" | translate }}</p>
	</div>
	<!-- USERNAME -->
	<div class="form-group mt-3 position-relative">
		<!-- <label for="username">{{ "LOGIN.USERNAME" | translate }}</label> -->
		<input type="text" class="form-control" name="usern" id="usern" placeholder="{{ 'LOGIN.USERNAME_HINT' | translate }}" [(ngModel)]="userName" #usern="ngModel" autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false" required />
		<span class="error-message" [hidden]="!(usern.touched && usern.invalid)">{{ "MESSAGE.REQUIRED" | translate }}</span>
	</div>

	<!-- PASSWORD -->
	<div class="form-group position-relative mt-4">
		<fa-icon [icon]="showPwd ? faEyeOpen : faEyeClosed" (click)="clickTogglePassword()" aria-hidden="true" class="show-psw-icon"></fa-icon>
		<input type="text" class="form-control" name="passw" id="passw" [ngClass]="showPwd ? '' : 'hide' " placeholder="{{ 'LOGIN.PASSWORD_HINT' | translate }}" [(ngModel)]="userPassword" autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false" #passw="ngModel" required />
		<span class="error-message ng-hide" [hidden]="!(passw.touched && passw.invalid)"> {{ "MESSAGE.REQUIRED" | translate }} </span>
	</div>

	<div class="button-container mt-5">
		<button type="submit" class="btn btn-secondary" [disabled]="loginForm.invalid && session.showLogin">{{ "BUTTONS.LOGIN_BUTTON" | translate }}</button>
	</div>
</form>
