import { Component, OnInit, AfterViewInit } from '@angular/core'
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { SessionService } from 'src/app/services/session.service'

import { faCircleCheck, faCircleXmark, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

export enum stateType {
	SUCCESS,
	FAIL,
	WARNING,
}

@Component({
	selector: 'message-modal',
	templateUrl: './message.modal.html',
	styleUrls: ['./message.modal.scss'],
	providers: [NgbModal],
})
export class MessageModal implements OnInit, AfterViewInit {
	state: stateType
	show: boolean

	texts: string[]

	isPatientAutoReg: boolean

	faCircleCheck = faCircleCheck
	faCircleXmark = faCircleXmark
	faTriangleExclamation = faTriangleExclamation

	constructor(private session: SessionService, public activeModal: NgbActiveModal) {
		this.state = stateType.SUCCESS
		this.show = false
		this.texts = []

		this.isPatientAutoReg = this.session.isPatAut
	}

	ngOnInit(): void {}

	ngAfterViewInit(): void {
		this.show = true
	}

	go() {
		this.activeModal.close()
	}

	close() {
		this.activeModal.dismiss()
	}
}
