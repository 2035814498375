<div class="row d-flex justify-content-center">
	<div class="col-lg-10 col-xl-10 col-xxl-8 col-12 position-relative p-xs-0">
		<div class="modal-container d-flex flex-column justify-content-center">
			<div class="loader-container" [ngClass]="isLoading ? 'show' : '' ">
				<div [hidden]="false" class="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>

			<anagraphic *ngIf="tabs[0].active" [tabId]="tabs[0].id"></anagraphic>

			<anamnesi *ngIf="tabs[1].active" [tabId]="tabs[1].id" (handleGoBackToAnagraphic)="changeTab($event, true)"></anamnesi>

			<agreement *ngIf="tabs[2].active" [tabId]="tabs[2].id"></agreement>
		</div>
	</div>
</div>
