// 21.06.2019 riordino codice
// dati sensibili, arrivano e partono sempre crittati
export class Address {
  address_label: number; // vale sempre uno
  address_line1: string;
  // address_line2: string;
  zip: string; // non usiamo
  city: string;
  province: string;
  country: string;
  phone: string;
  //phone2: string;  // non usiamo
  ref_email: string;
  organization: string;

  // 10.03.2021 con dati in chiaro
  // si puo' passare anche la bag con altri elementi, qui prende solo i suoi
  constructor(decrittBag?) {
    this.address_label = 1; // fisso
    this.address_line1 = '';
    this.city = '';
    this.province = '';
    this.country = '';
    this.ref_email = '';
    this.phone = '';
    this.organization = '';
    this.zip = '';

    if (decrittBag) {
      /*
        this.address_line1 = decrittBag.address_line1;
        this.city = decrittBag.city;
        this.country = decrittBag.country;        
        this.province = decrittBag.province;
        this.organization = decrittBag.organization;
        */

      // 15.09.2022 prende solo i campi con lo stesso nome
      var myJsonObj = { ...decrittBag };
      if (myJsonObj != null) {
        Object.assign(this, myJsonObj);
      }

      if (decrittBag.email != null) this.ref_email = decrittBag.email;
      //else if(decrittBag.ref_email != null)   // 10.01.2022 aggiunta gestione multi nome per i campi
      //  this.ref_email = decrittBag.ref_email;

      // servono entrambi nomi, fix (bug 211)
      //if(decrittBag.phone1 != null)
      //  this.phone1 = decrittBag.phone1;
      //else
      if (decrittBag.phone != null) this.phone = decrittBag.phone;
    }
  }

  /* 29.08.2022 tolto doppione 
    static initFromBag(bagCri){  
      var addr = new Address();           
      
      addr.address_line1 = bagCri['address_line1']; 
      addr.city = bagCri['city'];        
      addr.zip = bagCri['zip'];
      addr.province = bagCri['province']; 
      addr.country = bagCri['country']; 
      
      addr.phone1 = bagCri['phone1'];  / (bug 211) vd costruttore che gestisce entrambi

      addr.ref_email = bagCri['email'];
      addr.organization = bagCri['organization'];

      return addr;
    }
    */

  // 24.08.2022 spostata qui per averla in un unico punto
  // 29.04.2020 per il report
  getAddressLine2(): string {
    let addr2 = '';

    if (this.city && this.province) addr2 = this.city + ' - ' + this.province;
    else if (this.city) addr2 = this.city;
    else if (this.province) addr2 = this.province;

    if (this.country) {
      if (addr2.length > 0) addr2 += ' - ' + this.country;
      else addr2 = this.country;
    }

    return addr2;
  }
}
