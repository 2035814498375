import { Component, OnInit, Input, OnDestroy, ViewChild } from '@angular/core'
import { SessionService } from 'src/app/services/session.service'
import { Util } from 'src/app/models/util.model'
import { FormGroup, FormControl, Validators } from '@angular/forms'

import { NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap'
import { Country, CountryOBJ } from 'src/app/models/countries.models'

const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()
const PNF = require('google-libphonenumber').PhoneNumberFormat

const moment = require('moment')

import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import { Patient, PatientObj } from 'src/app/models/patient.model'
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs'
import { SearchdropdownComponent } from 'src/app/elements/searchdropdown/searchdropdown.element'

@Component({
	selector: 'anagraphic',
	templateUrl: './anagraphic.component.html',
	styleUrls: ['./anagraphic.component.scss'],
})
export class AnagraphicComponent implements OnInit, OnDestroy {
	@Input() tabId: number
	@ViewChild(SearchdropdownComponent) searchDropdownComponent!: SearchdropdownComponent

	searchAddressUpdate: Subject<string> = new Subject<string>()

	patient: Patient

	checkingTin: boolean
	isValidForTin: boolean

	maxBirthDate: moment.Moment
	minBirthDate: moment.Moment

	countryDialCode: string
	country: string
	countryObj: CountryOBJ

	show: boolean

	userGender = [
		{ code: 1, descr: 'GENDER.1' },
		{ code: 2, descr: 'GENDER.2' },
		{ code: 3, descr: 'GENDER.3' },
	]

	countries: Country[]

	form: FormGroup

	faCalendar = faCalendarAlt

	constructor(public session: SessionService) {
		Util.debug('Constructor - AnagraphicComponent')
		this.patient = this.session.getPatient()

		let currDay = new Date()
		// this.maxBirthDate = { year: currDay.getFullYear(), month: currDay.getMonth() + 1, day: currDay.getDate() }
		this.minBirthDate = moment({ year: currDay.getFullYear() - 120, month: 1, day: 1 })

		this.show = true
		this.checkingTin = false
		this.isValidForTin = true

		this.maxBirthDate = moment()

		this.form = new FormGroup({
			firstName: new FormControl(this.patient.firstName, Validators.required),
			lastName: new FormControl(this.patient.lastName, Validators.required),
			birthDate: new FormControl(this.patient.birthDate, Validators.required),
			country: new FormControl(this.patient.country, Validators.required),
			gender: new FormControl(this.patient.gender, Validators.required),
			personalid: new FormControl(this.patient.personal_id),
			phone: new FormControl(this.patient.phoneNumber.split(' ')[1], [Validators.required, this.validateOtpPhone.bind(this)]),
			countryDialCode: new FormControl(this.patient.phoneNumber.split(' ')[0], Validators.required),
			email: new FormControl(this.patient.email, [Validators.nullValidator, this.emailValidator.bind(this)]),
		})

		this.session.getCountries().then((res) => {
			this.countries = res
			if (this.patient.countryObj) {
				setTimeout(() => {
					this.selCountry(this.patient.countryObj)
					this.searchDropdownComponent.extSelect(this.countries.find((el) => el.alpha3 === this.patient.country).name)
				}, 500)
			}
		})
	}

	ngOnInit(): void {
		Util.debug('ngOnInit - AnagraphicComponent')
		// console.log(this.patient)
		this.searchAddressUpdate.pipe(debounceTime(800)).subscribe((value) => {
			// console.log(value)
			this.session
				.checkTin(value.toUpperCase(), this.form.get('country').value)
				.then((res) => {
					// console.log(res)
					this.checkingTin = false
					this.isValidForTin = true

					if (res) {
						this.form.get('personalid').setErrors(null)
					} else {
						this.form.get('personalid').setErrors({ ['invalid tin']: true })
					}
				})
				.catch((err) => {
					console.log(err)
					this.checkingTin = false
					let errType = err.error
					let errrStatus = err.status
					// console.log(errType.includes('Country Not Found'))
					if (errType.includes('Country Not Found') && errrStatus == 422) {
						this.form.get('personalid').setErrors(null)
						this.isValidForTin = false
					} else {
						this.form.get('personalid').setErrors({ ['invalid tin']: true })
					}
				})
		})
	}

	private validateOtpPhone(control: FormControl): { [s: string]: boolean } {
		if (control.value && control.value != '') {
			let formNum = control.value.toString()

			if (formNum === '' || formNum === undefined) {
				return { ['No number inserted']: true }
			}

			if (formNum.length == 1) {
				return { ['Invalid number inserted']: true }
			}

			if (this.countryObj) {
				const number = phoneUtil.parse(formNum, this.countryObj.countryIso2)

				if (phoneUtil.isValidNumberForRegion(number, this.countryObj.countryIso2)) {
					return null
				} else {
					return { ['Invalid number inserted']: true }
				}
			} else {
				return { ['No country selected']: true }
				return null
			}
		}
	}

	private emailValidator(control: FormControl): { [s: string]: boolean } {
		if (control.value) {
			var valid = control.value.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)

			if (valid == null) {
				return { ['Invalid email']: true }
			}
		}
		return null
	}

	public onDatePickerClick(event: Event, ngbDatepicker: NgbInputDatepicker) {
		event.preventDefault()
		ngbDatepicker.toggle()
	}

	public onSubmit() {
		Util.debug('AnagraphicComponent - reactive form submitted')

		if (!this.form.invalid) {
			let value = this.form.get('birthDate').value
			let personalId = this.form.value.personalid

			this.patient.firstName = this.form.value.firstName
			this.patient.lastName = this.form.value.lastName
			this.patient.birthDate = moment(value).format('YYYY-MM-DD')
			this.patient.country = this.form.value.country
			this.patient.personal_id = personalId.toUpperCase()
			this.patient.gender = this.form.value.gender
			this.patient.phoneNumber = this.form.value.countryDialCode + ' ' + this.form.value.phone
			this.patient.email = this.form.value.email

			this.patient.countryObj = this.countryObj
			// console.log(this.form)
			// console.log(this.patient)

			let patObj: PatientObj = new PatientObj(this.patient)
			patObj.id = this.tabId

			this.show = false

			this.session.createPatient.next(patObj)
		}
	}

	public selCountry(selCountry: CountryOBJ) {
		Util.debug('AnagraphicComponent - selCountry: ' + selCountry.countryIso3)
		// console.log(selCountry)
		this.countryObj = selCountry
		this.countryDialCode = selCountry.dialCode
		this.form.get('countryDialCode').setValue(this.countryDialCode)

		this.country = selCountry.countryIso3
		this.form.get('country').setValue(this.country)
		//resetto il phone number
		// this.form.get('phone').setValue('')
		this.form.get('phone').updateValueAndValidity()
		this.onPersonalIdEdit()
		if (selCountry.tinFlag === 'Y') {
			this.form.get('personalid').setValidators([Validators.required])
			this.form.get('personalid').updateValueAndValidity()
		} else {
			this.form.get('personalid').clearValidators()
			this.form.get('personalid').updateValueAndValidity()
		}
	}

	public resetCountry(event) {
		Util.debug('AnagraphicComponent - resetCountry: ' + event)
		if (event) {
			this.country = ''
			this.countryDialCode = ''
			this.form.get('countryDialCode').setValue('')

			this.form.get('country').setValue('')
			this.countryObj = null
		}
	}

	public onPersonalIdEdit() {
		let personalId = this.form.value.personalid

		if (personalId && personalId.length > 0 && this.form.value.country && this.isValidForTin) {
			this.checkingTin = true
			this.searchAddressUpdate.next(personalId)
		}
	}

	ngOnDestroy(): void {
		this.searchAddressUpdate.unsubscribe()
	}
}
