import { Injectable } from '@angular/core'
import { DateAdapter } from '@angular/material/core'
import { Router, ActivatedRoute } from '@angular/router'
import { HttpClient } from '@angular/common/http'
import { DatePipe } from '@angular/common'

import { DataModelService } from './data-model.service'

import { CryptoUtilsService } from '../services/crypto-utils.service'

import { LocalizationService } from '../internationalization/localization.service'

import { Config } from 'src/config'
import { Util } from '../models/util.model'
import { User, TokenResponse, UserType, LoggedUser, SessionStatus, CookieUser } from '../models/user.model'
import { Language } from '../models/navbar.model'
import { Country } from '../models/countries.models'
import { Address } from '../models/address.model'
import { ProfileJson, ProfileRequest, ProfilesResponse } from '../models/profile.model'
import { UAParser } from 'ua-parser-js'
import { TranslateService } from '@ngx-translate/core'
import { PatientObj, Patient, patRegistration, patObj } from '../models/patient.model'
import { Subject } from 'rxjs'
import { Anamnesis, Answer } from '../models/anamnesis.model'
import { Agreeement } from '../models/agreements.model'

const parser = new UAParser()
const resultB = parser.getResult()
const browserN = resultB.browser.name

// export enum regType {
// 	OPTICIAN,
// 	PATIENT
// }

@Injectable({
	providedIn: 'root',
})
export class SessionService {
	public user: User
	private status: SessionStatus

	private patient: Patient

	public createPatient: Subject<PatientObj>

	private patientRegistration: patRegistration // per salvare i dati nella session storage

	public languages: Language[] // 30.03.2022 spostato da headers
	public lang: string
	public lang_suffix: string

	private loggedUser: LoggedUser // 22.02.2017

	// safariWarning: boolean
	// oldFeMessage: string
	redirectWord: string
	userInfo: string

	private tempToken: string

	public KEYBOX_LEN = 88
	public countries: Country[]

	public showLogin: boolean

	// QRCode pc_ = patient creation

	private pc_userId: string
	private pc_groupId: string
	private pc_anamId: string
	private pc_country: string
	private pc_uuid: string
	isFromQr: boolean
	isPatAut: boolean

	constructor(
		private http: HttpClient,
		private data: DataModelService,
		public cryptoUtils: CryptoUtilsService,
		private routing: Router,
		private activatedRoute: ActivatedRoute,
		private dateAdapter: DateAdapter<Date>,
		public translator: TranslateService,
		public datepipe: DatePipe,
		private localizService: LocalizationService //private tableSettings: TableSettings
	) {
		// window['SESSION'] = this ??

		this.status = SessionStatus.NOT_LOGGED
		this.user = new User(null)

		this.createPatient = new Subject<PatientObj>()

		this.lang = 'en'

		// this.safariWarning = false
		this.tempToken = ''

		this.initLanguages()

		let lang = this.localizService.getSavedLang()
		console.log(lang)
		this.setLanguage(lang)
		this.getCountries().then((res) => {
			this.countries = res
			this.initcountries()
		})

		this.loggedUser = new LoggedUser()

		this.showLogin = false

		this.redirectWord = ''
		this.userInfo = ''

		this.patientRegistration = new patRegistration()

		// check for Safari browser
		// console.log(browserN )
		// if (browserN == 'Safari' || browserN == 'Mobile Safari' || browserN == 'Tablet Safari') {
		// 	this.oldFeMessage = this.translator.instant('LOGIN.SAFARI')
		// 	this.safariWarning = true
		// }

		let path = routing.url

		if (!this.availablePath(path)) {
			// per evitre che si salvi il path come redirectworld se si fa f5 su una di quelle pagine
			let url = window.location.href
			let parts = url.split('/')
			this.redirectWord = parts.at(-1)

			this.userInfo = parts[2].split('.')[0].split('-').at(-1)
		}

		// QRCode
		this.pc_userId = ''
		this.pc_groupId = ''
		this.pc_anamId = ''
		this.pc_country = ''
		this.pc_uuid = ''
		this.isFromQr = false
		this.isPatAut = false

		this.activatedRoute.fragment.subscribe((data) => {
			// console.log(data)
			// in caso di registrazione paziente, non viene permesso di uscire dalla registrazione nel caso venga editato l'url. se viene editato l'url, si ritorna alla pagina iniziale /register
			this.patientRegistration = JSON.parse(sessionStorage.getItem('patientRegistration'))
			// console.log(this.patientRegistration)

			if (this.patientRegistration && this.patientRegistration.isPatAut && this.patientRegistration.isFromQr) {
				this.isFromQr = true
				this.isPatAut = true
				this.pc_anamId = this.patientRegistration.pc_anamId
				this.pc_groupId = this.patientRegistration.pc_groupId
				this.pc_userId = this.patientRegistration.pc_userId
				this.pc_country = this.patientRegistration.pc_country
				this.pc_uuid = this.patientRegistration.pc_uuid

				this.routing.navigate(['/register'])
			}

			if (data == 'qrcode') {
				this.isFromQr = true

				this.activatedRoute.queryParams.subscribe((data) => {
					// console.log(data)
					// console.log(data['userId'])
					if (data['userId']) {
						this.pc_userId = data['userId']
					}

					if (data['groupId']) {
						this.pc_groupId = data['groupId']
					}

					if (data['anamId']) {
						this.pc_anamId = data['anamId']
					}

					if (data['country']) {
						this.pc_country = data['country']
					}

					if (data['uuid']) {
						this.pc_uuid = data['uuid']
					}

					if (this.pc_userId != '' && this.pc_groupId != '' && this.pc_anamId != '' && this.pc_country != '' && this.pc_uuid != '') {
						this.isPatAut = true

						this.patientRegistration = new patRegistration()

						this.patientRegistration.isFromQr = true
						this.patientRegistration.isPatAut = true
						this.patientRegistration.pc_anamId = this.pc_anamId
						this.patientRegistration.pc_groupId = this.pc_groupId
						this.patientRegistration.pc_userId = this.pc_userId
						this.patientRegistration.pc_country = this.pc_country
						this.patientRegistration.pc_uuid = this.pc_uuid

						sessionStorage.setItem('patientRegistration', JSON.stringify(this.patientRegistration))

						this.routing.navigate(['/register'])
					}
				})
			}
		})
	}

	public getpc_userId() {
		return Number(this.pc_userId)
	}

	public getpc_groupId() {
		return Number(this.pc_groupId)
	}

	public getpc_uuid() {
		return this.pc_uuid
	}

	public checkUUIDValidity(): Promise<boolean> {
		const promise = new Promise<boolean>((resolve, reject) => {
			let request: any = this.buildBaseRequestNew()
			request.headers.Authorization = 'Bearer ' + this.pc_uuid
			request.method = 'POST'
			request.url = Config.bridgeCheckUuid
			// console.log(request)

			this.myPost(request, null)
				.then((resp) => {
					// console.log(resp)
					resolve(true)
				})
				.catch((err) => {
					console.log(err)
					reject(false)
				})
		})

		return promise
	}

	//promise per la auth guard sulla patient registration
	public patientAutoregAvailable(): Promise<boolean> {
		const promise = new Promise<boolean>((resolve, reject) => {
			if (this.isFromQr && this.isPatAut) {
				resolve(true)
			} else {
				resolve(false)
			}
		})

		return promise
	}

	private availablePath(path: string): boolean {
		// console.log(path)
		let ret = false
		let blockedpath = ['/createuser', '/register', '/profile']

		for (let i = 0; i < blockedpath.length; i++) {
			const blkpath = blockedpath[i]

			if (path.includes(blkpath)) {
				ret = true

				return ret
			}
		}

		return ret
	}

	initcountries() {
		for (let i = 0; i < this.countries.length; i++) {
			this.countries[i].alpha2 = this.countries[i].alpha2.toLowerCase()
		}
	}
	// check terms & privacy
	public checkModalInfo(selCountry) {
		for (let i = 0; i < this.countries.length; i++) {
			if (this.countries[i].alpha3 == selCountry) {
				if (this.countries[i].terms && this.countries[i].privacy) {
					return true
				} else {
					return false
				}
			}
		}
	}

	// PATIENT

	public setPatient(pat: Patient) {
		this.patient = pat
	}

	public getPatient() {
		return this.patient
	}

	public checkTin(tin: string, country: string): Promise<boolean> {
		let request: any = this.buildBaseRequestNew()
		// let country = this.patient.country
		request.url = Config.tinChecker + '/' + country + '/' + tin

		const promise = new Promise<boolean>((resolve, reject) => {
			this.myGet(request)
				.then((resp) => {
					// console.log(resp)
					resolve(resp)
				})
				.catch((err) => {
					reject(err)
				})
		})

		return promise
	}

	//LOAD ANAMNESI
	public loadPatientAnamnesi(): Promise<Anamnesis[]> {
		let request: any = this.buildBaseRequestNew()

		let country = this.patient.country

		request.url = Config.anamnesisEndpoint

		if (this.pc_anamId) {
			request.url += 'group=' + this.pc_anamId
		}

		if (country) {
			request.url += '&country=' + country
		}
		// console.log(request)

		const promise = new Promise<Anamnesis[]>((resolve, reject) => {
			this.myGet(request)
				.then((resp) => {
					// console.log(resp)
					resolve(resp.questions)
				})
				.catch((err) => {
					reject(err)
				})
		})

		return promise
	}

	//LOAD PATIENT AGREEMENT
	public loadPatientAgreemnt(): Promise<Agreeement[]> {
		let request: any = this.buildBaseRequestNew()
		let country = this.pc_country // l'agreemen deve essere accettato quello di dove si trova lo shop, quindi del optician, non la country inserita dal paziente

		request.url = Config.agreementEndpoint + 'target=patient'

		if (country) {
			request.url += '&country=' + country
		}

		const promise = new Promise<Agreeement[]>((resolve, reject) => {
			this.myGet(request)
				.then((resp) => {
					// console.log(resp)
					resolve(resp.agreements)
				})
				.catch((err) => {
					reject(err)
				})
		})

		return promise
	}

	//LOAD OPTICIAN PUBLIC KEY
	public loadOpticianPKey(user_id: number): Promise<string> {
		const promise = new Promise<string>((resolve, reject) => {
			let request: any = this.buildBaseRequestNew(this.patient.tokenResponse)
			request.url = Config.publickeyEndpoint + user_id

			// console.log(request)
			this.myGet(request)
				.then((resp) => {
					// console.log(resp)

					if (resp.userId == user_id) {
						resolve(resp.privateKey)
					} else {
						reject('wrong opt id')
					}
				})
				.catch((err) => {
					console.log(err)
					reject(err)
				})
		})

		return promise
	}

	//SEND NEW PATIENT TO BRIDGE
	public saveNewPatient(): Promise<boolean> {
		const promise = new Promise<boolean>((resolve, reject) => {
			let pat: patObj = new patObj(this.patient)
			// console.log(pat)

			let patientObject = JSON.stringify({ patient: pat })

			let request: any = this.buildBaseRequestNew(this.patient.tokenResponse)
			request.method = 'POST'
			request.url = Config.savepatientEndpoint

			// console.log(request)

			this.myPost(request, patientObject)
				.then((resp) => {
					// console.log(resp)

					resolve(true)
				})
				.catch((err) => {
					console.log(err)

					reject(false)
				})
		})

		return promise
	}

	// ###### LOGIN SECTION ######

	login(username: string, password: string) {
		var lang = 'en'

		var testMsg = '(session) login has been called for ' + username + ' lang: ' + lang
		Util.debug(testMsg)

		this.status = SessionStatus.LOGGING

		return this.obtainToken(username, password)
			.then((tokenData: TokenResponse) => {
				return this.loadProfile(tokenData)

					.then((profileData: ProfilesResponse) => {
						Util.debug('(session) OK login, loading profile for ' + username)

						this.loggedUser.validBrand = true
						this.loggedUser.pwd = password
						this.loggedUser.usrname = username

						if (profileData.profile && profileData.profile.client_ip) {
							this.loggedUser.myIp = profileData.profile.client_ip

							Util.debug('(S) [login] current ip: ' + this.loggedUser.myIp)
						}

						this.user = User.createUser(this.cryptoUtils, tokenData, username, password)
						Util.debug('(S login) user created, now initProfile')

						return this.user
							.initProfile(profileData)
							.then(() => {
								Util.debug('(S login) OK init profile, name: ' + this.user.firstname + ' last: ' + this.user.lastname + ' country: ' + this.user.getCountry())

								this.status = SessionStatus.LOGGED

								Util.debug('(S) saved status LOGGED')

								if (this.user.settings.lang && this.user.settings.lang != this.lang) {
									Util.debug('S (initAfterLogin) going to change language from ' + this.lang + ' to ' + this.user.settings.lang)
									this.setLanguage(this.user.settings.lang)
								}

								this.routing.navigateByUrl('/profile')
							})
							.catch((myErr) => {
								// keep eror together
								let msg = this.parseErrorMessage(myErr, 'trace')
								console.log('(S) KO refreshProfile ' + msg)

								return false
							})
					})
					.catch((err) => {
						var msg = this.parseErrorMessage(err, 'trace') // 28.03.2022
						console.log('KO login ' + msg)
						console.log(err)

						return false
					})
			})
			.catch((errTok) => {
				if (errTok.status == 400) {
					console.log('(S) Ko login, Not Authorized')
					this.status = SessionStatus.LOG_FAILED
				} else {
					let msg = errTok.data ? errTok.data.error : errTok.toString()
					console.log('(S) KO token ' + msg)
					this.status = SessionStatus.LOG_FAILED
					alert('Invalid username and/or password')
					//this.status = SessionStatus.ERROR;  // TODO, altro errore generico ?
				}

				return false
			})

		//console.log("(S) end method");  // no, esce subito
		//return true;
	}

	public encryptDataShort(myKey, myData) {
		return this.cryptoUtils.encryptDataWithPuk(myKey, myData)
	}

	private loadProfile(tokenData?): Promise<ProfilesResponse> {
		let request: any = this.buildBaseRequest(tokenData)
		request.url = Config.profilesEndpoint

		Util.debug('(S) [loadProfile] going to do the request to the server...')
		//return this.http.get<any>(Config.profilesEndpoint, this.buildBaseRequest(tokenData));
		//return this.http.get<any>(Config.profilesEndpoint, {headers: request.headers});

		return (
			this.myGet(request)
				/*
      .then((ris) => {
        console.log("(S) [loadProfile] ok");
        return ris;
      })*/
				.catch((err) => {
					console.log('(S) [loadProfile] ko')
					this.status = SessionStatus.NOT_LOGGED
					throw err
				})
		)

		/*
    return this.http(request)
    .then(function(profileResponse) {
      //console.log(profileResponse);  // togliere trace      
      return [tokenData, profileResponse];
    });
    */
	}
	// 21.03.2023 use this function after the subscribe process
	public savenewuser(rawAccessData, addr?, token?: TokenResponse) {
		let request: any = this.buildBaseRequest(token)
		request.method = 'POST'
		let myData

		if (addr && token) {
			request.url = Config.bridgeUserAdd
			myData = {
				user: rawAccessData,
				mainAddress: addr,
				userDevice: this.redirectWord || '',
				userInfo: this.userInfo || '',
			}
		} else {
			request.url = Config.bridgeUserUpdate
			myData = { user: rawAccessData }
		}

		Util.debug('(createNewUserInternal) sending POST ...')

		// response from the dridge server
		return this.myPost(request, myData)
	}

	public async vatChecker(vatInfo: { state: string; vatNumber: string }) {
		let request: any = this.buildBaseRequest()
		request.method = 'POST'
		request.url = Config.bridgeVatChecker

		Util.debug('(vatChecker) sending POST ...')

		return await this.myPost(request, vatInfo).catch((err) => console.log('error on vatChecker request:', err))
	}

	public async otpInit(otpInitInfo: { action: string; lang: string; phoneNumber: string, isEmail: string , email: string }) {
		let request: any = this.buildBaseRequest()
		request.method = 'POST'
		request.url = Config.bridgeOtpInit

		Util.debug('(otpInit) sending POST ...')

		return await this.myPost(request, otpInitInfo).catch((err) => console.log('error on otpInit request:', err))
	}

	public async otpVerify(otpVerifyInfo: { messageID: string; otp: string }) {
		let request: any = this.buildBaseRequest()
		request.method = 'POST'
		request.url = Config.bridgeOtpVerify

		Util.debug('(otpVerify) sending POST ...')

		return await this.myPost(request, otpVerifyInfo).catch((err) => {
			console.log('error on otpVerify request:', err)
			throw err
		})
	}

	public async getCountries() {
		let request: any = this.buildBaseRequest()
		request.method = 'GET'
		request.url = Config.countriesEndpoint

		Util.debug('(getCountries) sending GET ...')

		return await this.myGet(request).catch((err) => console.log('error on getCountries request:', err))
	}

	public redirect() {
		// this.logout()
		let path = ''

		if (this.isFromQrCode()) {
			path = Config.nexusLanding + '/' + this.redirectWord
		} else {
			path = Config.nexusLoginUrl
		}

		window.location.href = path
	}

	private obtainToken(username: string, password: string) {
		// 19.03.2020 test con forge, ok
		// pwd is salted and hashed before leaving the client
		// 04.01.2017 qui fare hash prima di postarlo --ls
		//var hash_pwd = cryptoJs.SHA256(username.toLowerCase()+password).toString();
		//var hash_pwd = this.cryptoUtils.getSHA256(username.toLowerCase()+password).toString();
		var hash_pwd = this.cryptoUtils.getSHA256(username.toLowerCase() + password).toString()

		//const headers = { 'caller': 'FEweb'};
		var message = {
			grant_type: 'password',
			username: username,
			password: hash_pwd,
		}

		Util.debug('(obtainToken) going to ask for ' + username)

		// 07.06.2022 moved on the caller
		//this.status = SessionStatus.LOGGING;

		// 18.11.2022 uso la myPost con la baseReq, cosi' forzo il caller
		//return this.http.post<any>(Config.tokenEndpoint, message)		//.map(response => response.json());
		let request: any = this.buildBaseRequest(null)
		request.url = Config.tokenEndpoint
		return this.myPost(request, message)
	}

	isLogging(): boolean {
		return this.status == SessionStatus.LOGGING
	}

	isLogged(): boolean {
		return this.status == SessionStatus.LOGGED
	}

	getStatus(): SessionStatus {
		return this.status
	}

	// ******* funzioni GET su utente loggato **************

	parseErrorMessage(myErr, level?) {
		let msg = ''

		if (myErr.data && myErr.data.error && myErr.data.error.error) {
			// 31.05.2022
			msg = myErr.data.error.error
		} else if (myErr.data) {
			msg = myErr.data.error
		} else if (myErr.error && myErr.error.error) {
			// 20.07.2022
			msg = myErr.error.error // il msg scritto dalle api
		} else if (myErr.statusText) {
			// 20.07.2022
			msg = ' ' + myErr.statusText // decodifica 404 in forbidden
		}

		if (msg == null || msg.length == 0) {
			msg = JSON.stringify(myErr)
		}
		if (msg.length > 40 && level == 'alert') {
			console.log('(S) [ERR] for alert, ' + msg)
			//msg = myErr.toString();  // piu' corto ?!
		}
		return msg
	}

	private buildBaseRequest(tokenData?: TokenResponse) {
		var token = !!tokenData ? User.buildToken(tokenData) : this.user.token

		// 28.04.2022 patch
		if (token == null || token == undefined) {
			token = ''
		}

		return {
			headers: {
				Authorization: token,
				caller: 'NEWeb', // 20.04.2020 aggiunto caller [ls]
				Pragma: 'no-cache', // 18.03.2022 toglie la cache
				'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
				Expires: '',
				'Access-Control-Allow-Origin': '*',
				// Expires: 0, //con "@angular/common": "^14.2.0" da un errore, accetta solo stringa o array
			},
		}
	}

	private buildBaseRequestNew(tokenData?: TokenResponse) {
		var token = !!tokenData ? User.buildToken(tokenData) : this.user.token

		if (token == null || token == undefined) {
			token = ''
		}

		return {
			headers: {
				Authorization: token,
				'X-Caller': 'NexusSelfRegistration',
				Pragma: 'no-cache',
				'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
				Expires: '',
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
			},
		}
	}

	//****** post put and get */

	private myGet(request) {
		//console.log("(S) [myGet] calling: "+request.url);
		return this.data.myGet(request).catch((err) => {
			console.log('(S) [myGet] ko')
			throw err
		})
	}

	// 21.01.2022
	private myPut(request, message) {
		//console.log("(S) [myPut] calling: "+request.url);
		return this.data.myPut(request, message).catch((err) => {
			console.log('(S) [myPut] ko')
			throw err
		})
	}

	// 21.01.2022
	public myPost(request, message) {
		// console.log(request);
		// console.log(message);
		Util.debug('(S) [myPost] calling: ' + request.url)
		return this.data.myPost(request, message).catch((err) => {
			console.log('(S) [myPost] ko')
			throw err
		})
	}

	// 28.01.2022
	private myDelete(request) {
		Util.debug('(S) [myDelete] calling: ' + request.url)
		return this.data.myDelete(request).catch((err) => {
			console.log('(S) [myDel] ko')
			throw err
		})
	}

	// 21.09.2022 per passare dati alla telerefract senza aprire nuova finestra
	public myExternalPost(myUrl, message) {
		console.log('S (myExternalPost) going to call ' + myUrl)
		//console.log("S (myExternalPost) params: "+message.toString()); // ko

		return this.http.post<any>(myUrl, message)
	}

	// ********** Languages *********

	getLanguage() {
		if (this.lang == null || this.lang == '') this.lang = 'en'
		return this.lang
	}

	setLanguage(language, flagManual?) {
		if (language != null) {
			// 14.04.2020 aggiunto test [ls]
			Util.debug('(setLanguage) got ' + language)

			// 03.05.2022
			let fullLang = ''
			let shortLang = ''
			if (language.length == 2) {
				fullLang = language + '_' + language.toUpperCase()
				shortLang = language
			} else {
				fullLang = language
				shortLang = language.substring(0, 2)
			}

			// Set locale for  material date picker
			this.dateAdapter.setLocale(fullLang)
			Util.debug('S (setLanguage) full: ' + fullLang)

			// 16.05.2022 usiamo il service
			//this.translator.use(fullLang.concat(localStorage.getItem('language_suffix')) );  // 03.05.2022 spostata qui
			// let suff = localStorage.getItem('language_suffix')
			//this.localizService.useLanguage(fullLang.concat(localStorage.getItem('language_suffix')));
			this.localizService.useLanguage(fullLang)

			//this.lang = language.substring(0,2);
			this.lang = shortLang
		}
	}

	onLanguageClick(event: any, language?: string) {
		//onLanguageClick(event: any, lab?: any) {

		Util.debug('S (onLanguageClick) clicked language: ' + language)
		//console.log(event);

		let shortLang = language
		let fullLang = language + '_' + language.toUpperCase()

		Util.debug('S (onLanguageClick) ' + fullLang + ' short: ' + shortLang)

		let flagManual = true // 19.05.2022 - 03.03.2022
		//this.translator.use(fullLang);  // spostato dentro la setLang
		this.setLanguage(shortLang, flagManual)
	}

	// navbar elements for languages
	private createLanguagePush(lTitle, code) {
		this.languages.push(
			Language.createLanguage(
				lTitle,
				() => {
					this.onLanguageClick(code)
				},
				code
			)
		)
	}

	// creation languages for navbar dropdown
	private initLanguages() {
		this.languages = []

		this.createLanguagePush('ENGLISH', 'en_EN')
		this.createLanguagePush('ITALIANO', 'it_IT')
		this.createLanguagePush('FRANÇAIS', 'fr_FR')
		this.createLanguagePush('DEUTSCH', 'de_DE')
		this.createLanguagePush('PORTUGUÊS', 'pt_PT')
		this.createLanguagePush('ESPAÑOL', 'es_ES')
		this.createLanguagePush('中文语', 'zh_ZH')

		console.log('[Header] - tot languages: ' + this.languages.length)
	}

	public getDtCountries() {
		return this.countries
	}

	getUserId() {
		return this.user.user_id
	}

	getUsername(): string {
		return this.user.username
	}

	getFirstName(): string {
		return this.user.firstname
	}

	getLastName(): string {
		return this.user.lastname
	}

	// 15.06.2017
	getFullName(): string {
		return this.user.firstname + ' ' + this.user.lastname
	}
	// 14.06.2022
	getUserBrand(): string {
		let ret = ''
		if (this.user && this.user.settings) {
			ret = this.user.settings.brand
			Util.debug('(getUserBrand) ' + ret)
		}
		return ret
	}

	getCode(): string {
		return this.user.code
	}

	getEmail(): string {
		return this.user.mainAddress.ref_email
	}

	getPhone(): string {
		return this.user.mainAddress.phone
	}

	// 14.02.2017
	getOrganization(): string {
		//return this.user.mainAddress.organization; // other_data;
		return this.user.getOrganization()
	}

	// 26.05.2022
	getUserCountry(): string {
		return this.user.getCountry()
	}

	// 25.07.2017 immagine in base64 con logo farmacia o timbro del medico
	getLogo(): string {
		return this.user.logo
	}

	// 31.05.2017
	getCurrentUser(): User {
		return this.user
	}
	isLevel1(): boolean {
		return this.user.type == UserType.OPERATOR // entrambi i livelli 1
	}
	// livello 1 specifico
	isOptician(): boolean {
		var ret = this.isLevel1() && this.user.subType == UserType.OPTICIAN
		return ret
	}
	isDoctor(): boolean {
		//var ret = this.isLevel1() && (this.user.subType != data.UserType.OPTICIAN);
		var ret = this.isLevel1() && this.user.subType == UserType.DOCTOR
		return ret
	}
	isLevel2(): boolean {
		return this.user.type == UserType.DEALER
	}
	isSpecialist(): boolean {
		var ret = this.isLevel2() && this.user.subType == UserType.SPECIALIST
		//console.log("(isSpecialist) "+ret);
		return ret
	}
	isRdsCustomer(): boolean {
		return this.userInfo.includes('dneye')
	}
	isFromQrCode(): boolean {
		return this.redirectWord !== ''
	}

	public isProduction() {
		return Config.isProductionMode
	}

	public isStaging() {
		return Config.isStagingMode
	}

	updateProfile(profileDraft: any) {
		let request: any = this.buildBaseRequest()

		request.method = 'PUT'
		//request.url = Config.profilesEndpoint + "/myId";  // fake id, lo prende dal token
		request.url = Config.profilesEndpoint + '/' + this.getUserId() // 10.03.2022

		// 09.02.2017 alcuni campi vanno crittati, prima dell'invio: nome, cognome, phone
		//console.log("(updateProfile) critt alcuni campi");
		var myBag = this.cryptoUtils.generateBag()

		var dataReq: ProfileRequest
		var myProfile: ProfileJson
		myProfile = new ProfileJson()
		myProfile.addresses = [] // lo costruisco dopo con i dati crittati

		// 11.02.2021 solo order_num in chiaro
		if (profileDraft.order_reg_num) myProfile.order_reg_num = profileDraft.order_reg_num

		// tutti gli altri campi sono crittati

		myBag['firstname'] = profileDraft.firstName
		myBag['lastname'] = profileDraft.lastName

		myBag['phone'] = profileDraft.phone
		myBag['email'] = profileDraft.email
		myBag['organization'] = profileDraft.organization

		myBag['address_line1'] = profileDraft.address
		myBag['city'] = profileDraft.city
		myBag['province'] = profileDraft.province
		myBag['country'] = profileDraft.country

		this.cryptoUtils.purge(myBag)

		//console.log("(updateProfile) costruita bag toCritt "+bag.firstname+" by user "+this.getUsername());

		// TODO per diverse tipologie di utente
		//var goodKey = this.user.keyDoctor; se patient
		//var goodKey = this.user.keyPhoto
		let goodKey = this.user.getKeyPhoto() // 02.03.2023 ne fa una copia

		return this.cryptoUtils.encryptFromStringToBase64Content(goodKey, myBag).then((bagCri) => {
			myProfile.firstname = bagCri['firstname']
			myProfile.lastname = bagCri['lastname']

			var myAddr = new Address(bagCri)
			myProfile.addresses.push(myAddr)

			Util.debug('(updateProfile) phone ? ' + myAddr.phone) // 29.08.2022

			dataReq = {
				data: myProfile,
			}

			return this.myPut(request, dataReq)
				.then(() => {
					Util.debug('S (updateProfile) ok, done')
					//console.log(resp);  // null
					//return
					// this.refreshProfile() // 11.01.2022 serve xche' se riapro, compaiono i valori vecchi
					return true // non serve aspettare il refresh, intanto proseguo
				})
				.catch((err) => {
					throw err // rilancia la exception
				})
		})
	}
	public logout() {
		Util.debug('(S) logout has been called')

		// 04.10.2021 se sta fallendo la login, non ho userId da de-loggare
		let currId = this.getUserId()
		let request: any

		if (currId != null) {
			// 05.10.2020 invia una chiamata alle api per invalidare il token
			// intanto lo carico prima di annullare l'oggetto user
			request = this.buildBaseRequest()
			//request.method = "POST";
			request.url = Config.profilesEndpoint + '/' + currId + '/logout'
		}

		// *** annullo lato client ***********

		// 02.11.2022 poi devo inviare il logout alla piattaforma di teleRefract

		// window.sessionStorage.removeItem('user')
		// // 07.06.2022 Remove all saved data from sessionStorage
		// window.sessionStorage.clear()

		if (currId != null) {
			// 05.10.2020 invia una chiamata alle api per invalidare il token
			this.http.post<any>(request.url, null, { headers: request.headers }).subscribe(
				(resp) => {
					//console.log("(S logout) resp ");  // ok
				},
				(err) => {
					console.log('(S logout) err ')
					console.log(err) // togliere dopo i test
				},
				() => {
					Util.debug('(S logout) done, checking routes...')
				}
			)
		} else {
			Util.debug('(S logout) no user_id')
		}
	}
}
