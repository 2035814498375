import { Attribute, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { faXmark, faCheck } from '@fortawesome/free-solid-svg-icons'

import { Country } from 'src/app/models/countries.models'

@Component({
	selector: 'searchdropdown',
	templateUrl: './searchdropdown.element.html',
	styleUrls: ['./searchdropdown.element.scss'],
})
export class SearchdropdownComponent implements OnInit {
	@Input() countries: Country[]

	@Output() outcountry = new EventEmitter<{
		countryIso2: string
		countryIso3: string
		dialCode: string
		ecommerceFlag: 'Y' | 'N'
		tinFlag: 'Y' | 'N'
		otpViaEmail: 'Y' | 'N'
	}>()
	@Output() cancelCountry = new EventEmitter<boolean>()

	@ViewChild('listCountries', { static: false }) listCountries: ElementRef
	@ViewChild('country') country: ElementRef

	currentValue: any
	dropdownOpen: boolean = false
	visible: boolean = false
	drpcountry: string = ''

	countrySelected: boolean

	dataFiltered: Country[] = []

	faXmark = faXmark
	faCheck = faCheck

	constructor(@Attribute('type') public type: string, public translator: TranslateService) {
		this.countrySelected = false
	}

	ngOnInit(): void {
		if (this.countries != null) {
			console.log('(Searchdrop) tot: ' + this.countries.length)
		}

		this.dataFiltered = this.countries.sort((a: Country, b: Country) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
	}

	select(country: Country) {
		console.log('(Searchdrop) choosen: ' + country.alpha2 + ' ' + country.alpha3)
		this.visible = false
		this.drpcountry = country.name
		this.countrySelected = true

		this.outcountry.emit({
			countryIso2: country.alpha2,
			countryIso3: country.alpha3,
			dialCode: country.dialCode,
			ecommerceFlag: country.ecommerce,
			tinFlag: country.ptinRequired,
			otpViaEmail: country.oTPEmail,
		})
	}

	public extSelect(country: string) {
		this.drpcountry = country
	}

	resetCountry() {
		this.country.nativeElement.value = ''
		this.countrySelected = false
		this.drpcountry = ''
		this.cancelCountry.emit(true)
		this.dataFiltered = this.countries.sort((a: Country, b: Country) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
	}

	filter() {
		this.visible = this.drpcountry !== ''

		this.dataFiltered = this.countries.filter((country) => {
			return country.name.toLowerCase().startsWith(this.drpcountry.toLowerCase())
		})
	}

	onBlur() {
		this.visible = false
	}
}
