<!-- panel-body -->

<div class="row d-flex justify-content-center">
	<div class="col-md-6 col-xl-10 col-xxl-8 col-12 position-relative p-xs-0">
		<div class="modal-container">
			<div class="my-2">
				<h5 class="fw-bold mb-2">{{ "PROFILE.CHECK" | translate }}</h5>

				<p>{{ "PROFILE.CHECK_SEC" | translate }}</p>
				<p>{{ "PROFILE.CHECK_LAST" | translate }}</p>
			</div>
			<form name="profileForm" #profileForm="ngForm" novalidate>
				<div class="row">
					<div class="col-12">
						<div class="row">
							<div class="form-group col-12 col-md-6 position-relative">
								<label for="usrname">{{ "PROFILE.USERNAME" | translate }}</label>
								<input type="text" class="form-control" id="usrname" name="usrname" [ngModel]="currUser.username" disabled />
							</div>
							<div class="form-group col-12 col-md-6 position-relative">
								<label for="code">{{ "PROFILE.CODE" | translate }}</label>
								<input type="text" class="form-control" id="code" name="code" [ngModel]="currUser.code" disabled />
							</div>
						</div>

						<div class="row">
							<div class="form-group col-12 col-md-6 position-relative">
								<label for="first-name">{{ "PROFILE.FIRST_NAME" | translate }} *</label><br />
								<input type="text" class="form-control" name="firstName" id="first-name" placeholder="{{ 'PROFILE.FIRST_NAME_HINT' | translate }}" [(ngModel)]="edits.firstName" #fn="ngModel" disabled />
								<div class="error-wrapper" [hidden]="!(fn.touched && fn.invalid)">
									<p class="error-message">{{ "MESSAGE.REQUIRED" | translate }}</p>
								</div>
							</div>

							<div class="form-group col-12 col-md-6 position-relative">
								<label for="last-name">{{ "PROFILE.LAST_NAME" | translate }}</label><br />
								<input type="text" class="form-control" name="lastName" id="last-name" placeholder="{{ 'PROFILE.LAST_NAME_HINT' | translate }}" [(ngModel)]="edits.lastName" #ln="ngModel" disabled />
								<div class="error-wrapper" [hidden]="!(ln.touched && ln.invalid)">
									<p class="error-message">{{ "MESSAGE.REQUIRED" | translate }}</p>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="form-group col-12 col-md-6 position-relative">
								<label for="address">{{ "PROFILE.ADDRESS" | translate }}</label><br />
								<input type="text" class="form-control" name="address" id="address" placeholder="{{ 'PROFILE.ADDRESS_HINT' | translate }}" [(ngModel)]="edits.address" #addr="ngModel" disabled />
								<div class="error-wrapper" [hidden]="!(addr.touched && addr.invalid)">
									<p class="error-message">{{ "MESSAGE.REQUIRED" | translate }}</p>
								</div>
							</div>

							<div class="form-group col-12 col-md-6 position-relative">
								<label for="city">{{ "PROFILE.CITY" | translate }}</label><br />
								<input type="text" class="form-control" id="city" name="city" placeholder="{{ 'PROFILE.CITY_HINT' | translate }}" [(ngModel)]="edits.city" #city="ngModel" disabled />
								<div class="error-wrapper" [hidden]="!(city.touched && city.invalid)">
									<p class="error-message">{{ "MESSAGE.REQUIRED" | translate }}</p>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="form-group col-12 col-md-6 position-relative">
								<label for="province">{{ "PROFILE.PROVINCE" | translate }}</label><br />
								<input type="text" class="form-control" id="province" name="province" placeholder="{{ 'PROFILE.PROVINCE_HINT' | translate }}" [(ngModel)]="edits.province" #prov="ngModel" disabled />
								<div class="error-wrapper" [hidden]="!(prov.touched && prov.invalid)">
									<p class="error-message">{{ "MESSAGE.REQUIRED" | translate }}</p>
								</div>
							</div>

							<div class="form-group col-12 col-md-6 position-relative">
								<label for="country">{{ "PROFILE.COUNTRY" | translate }}</label>
								<input type="text" class="form-control" id="country" name="country" [value]="edits.country" disabled />
							</div>
						</div>

						<div class="row">
							<div class="form-group col-12 col-md-6 position-relative">
								<label for="phone">{{ "PROFILE.PHONE" | translate }}</label>
								<input type="text" class="form-control" name="phone" placeholder="{{ 'PROFILE.PHONE_HINT' | translate }}" [(ngModel)]="edits.phone" #phone="ngModel" disabled />
							</div>

							<div class="form-group col-12 col-md-6 position-relative" *ngIf="session.isOptician()">
								<label for="organization">{{ "PROFILE.ORGANIZATION" | translate }} *</label><br />
								<input type="text" class="form-control" id="organization" name="organization" #org="ngModel" placeholder="{{ 'PROFILE.ORGANIZATION_HINT' | translate }}" [(ngModel)]="edits.organization" disabled />
								<div class="error-wrapper" [hidden]="!(org.touched && org.invalid)">
									<p class="error-message">{{ "MESSAGE.REQUIRED" | translate }}</p>
								</div>
							</div>

							<div class="form-group col-12 col-md-6 position-relative">
								<div class="mini-modal-warning" *ngIf="email.invalid">
									<div class="arrow-up"></div>
									<div class="warning-header warning">
										<fa-icon [icon]="faTriangleExclamation" aria-hidden="true" class="warning error-blink"></fa-icon>
										{{ "MISC.ATTENTION" | translate }}
									</div>
									<div class="warning-body" [innerHTML]="attentionBody">
										<span></span>
									</div>
								</div>

								<label for="email">{{ "PROFILE.EMAIL" | translate }}</label>
								<!--pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"-->
								<input
									type="text"
									class="form-control"
									name="email"
									placeholder="{{ 'PROFILE.EMAIL_HINT' | translate }}"
									[(ngModel)]="edits.email"
									#email="ngModel"
									pattern='^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
									required />
							</div>
						</div>

						<div class="row">
							<div class="form-group col-12 col-md-6 position-relative" *ngIf="session.isDoctor() || session.isSpecialist()">
								<label for="order">{{ "PROFILE.LICENCE_NUM" | translate }}</label>
								<input type="text" class="form-control" name="order" id="order" placeholder="{{'PROFILE.MODAL_ORDER_REG_NUM_HINT' | translate}}" [(ngModel)]="edits.order_reg_num" readonly />
							</div>
						</div>

						<div class="row">
							<div class="form-group col-12 col-md-6 position-relative">
								<button type="submit" class="btn btn-danger" [disabled]="profileForm.invalid" (click)="editProfile()">{{ "BUTTONS.SAVE" | translate }}</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
