export class Config {
	//100% static
	static all = 'all'

	//from config.json
	static isProductionMode: boolean
	static isStagingMode: boolean // 27.10.2022 serve per Telerefract

	static cryptographyMode: String

	static apiEndpoint: String
	static telerefractUrl: String // 02.11.2022
	static api_bridgeEndpoint: String
	static nexusLanding: String
	static nexusFeUrlRecover: String
	static nexusLoginUrl: string

	//built from json data
	static tokenEndpoint
	static pingEndpoint
	static sapEndpoint // 07.12.2022

	static bridgeUserAdd
	static bridgeUserUpdate
	static bridgeVatChecker
	static bridgeOtpInit
	static bridgeOtpVerify
	static bridgeCheckUuid

	static anamnesisEndpoint
	static agreementEndpoint
	static publickeyEndpoint
	static savepatientEndpoint
	static tinChecker
	static countriesEndpoint

	static profilesEndpoint
	static brand: String

	static BR_DEFAULT = 'Default'
	static BR_CLALIT = 'Clalit'
	static BR_RDS = 'RDS'
	static BR_ESSILOR = 'Essilor'
	static BR_ZEISS = 'Zeiss'

	static BUILD = '3.2.1'

	static PR_OPTICIAN = 'Optician'
	static PR_DOCTOR = 'Doctor'
	static PR_SPECIALIST = 'Specialist'
	static PR_DISTRIB = 'Distributor'
	static PR_ADMIN = 'Admin'
	static PR_VICE = 'Vice'
	static PR_MANAGER = 'Manager'
	static PR_STATS = 'Statistician'
	static PR_SERVICE = 'Service'
	static PR_SUPPORT = 'Support'
	static PR_PATIENT = 'Patient'

	static ALL_LEV1 = 'lev1' // usato nelle statistiche

	// 08.02.2021
	static SUB_STD = 'Std'
	static SUB_SUPER = 'Super'
	static SUB_MINI = 'Mini'
	static SUB_PRIVATE = 'Private'

	static SUBTYPES = [Config.SUB_STD, Config.SUB_SUPER, Config.SUB_MINI]

	static CHOICE_ALL = 'All'
	static CHOICE_MAN = 'Manual'

	static ANAGTAB = 'Anagraphic'
	static ANAMTAB = 'Anamnesi'
	static AGREMTAB = 'Agreeement'

	static PDF_FORMATS = ['A4', 'LETTER']
	static DATE_FORMATS = ['MM/DD/YYYY', 'DD/MM/YYYY']

	// 19.10.2021 diagnosis groups
	static DIAGN_GRP_0 = '0 - No Codes'
	static DIAGN_GRP_1 = '1 - ICD10'
	static DIAGN_GRP_2 = '2 - ICD hidden'
	static DIAGN_GRP_3 = '3 - test'

	static DIAGN_GROUPS = [Config.DIAGN_GRP_0, Config.DIAGN_GRP_1, Config.DIAGN_GRP_2]

	static load(jsonConfig) {
		//console.log(jsonConfig);  // 10.03.2021 tolta trace

		//from config.json
		Config.apiEndpoint = jsonConfig.apiEndpoint
		Config.api_bridgeEndpoint = jsonConfig.api_bridgeEndpoint
		Config.nexusLanding = jsonConfig.nexusLanding
		Config.nexusLoginUrl = jsonConfig.nexusLoginUrl
		//Config.phpEndpoint = jsonConfig.phpEndpoint;
		Config.isProductionMode = !!jsonConfig.production
		Config.isStagingMode = !!jsonConfig.staging

		Config.cryptographyMode = jsonConfig.cryptography ? jsonConfig.cryptography : 'standard'
		Config.brand = jsonConfig.brand

		Config.telerefractUrl = jsonConfig.telerefractUrl

		// 10.03.2021 nuova trace ridotta

		console.log('[init] B:' + jsonConfig.brand + ', Prod? ' + Config.isProductionMode + ', v:' + Config.BUILD)
		// console.log('[init] API: ' + jsonConfig.apiEndpoint)

		var apiPath = '/api/v1' // 20.04.2021 tutte a parte la token-login

		//built from json data
		//Config.tokenEndpoint = Config.apiEndpoint + "/../../oauth/token";
		// Config.tokenEndpoint = Config.apiEndpoint + '/oauth/token'; // 25.10.2021
		Config.tokenEndpoint = Config.apiEndpoint + '/oauth/user_token'
		Config.pingEndpoint = Config.apiEndpoint + '/ping' // 05.05.2022

		Config.apiEndpoint = Config.apiEndpoint + apiPath // 20.04.2021

		Config.bridgeUserAdd = Config.api_bridgeEndpoint + '/user/add'
		Config.bridgeUserUpdate = Config.api_bridgeEndpoint + '/user/update'
		Config.bridgeVatChecker = Config.api_bridgeEndpoint + '/user/vatchecker'
		Config.bridgeOtpInit = Config.api_bridgeEndpoint + '/vbr/otp'
		Config.bridgeOtpVerify = Config.api_bridgeEndpoint + '/vbr/verify'
		Config.nexusFeUrlRecover = Config.nexusLoginUrl + '?targetpage=recover'
		Config.profilesEndpoint = Config.apiEndpoint + '/profiles'
		Config.anamnesisEndpoint = Config.api_bridgeEndpoint + '/vbr/anamnesis?' //{{br_api_ur}}/vbr/anamnesis?group=1&country=DEU
		Config.agreementEndpoint = Config.api_bridgeEndpoint + '/vbr/agreements?' // {{br_api_ur}}/vbr/agreement?target=patient&country=ITA
		Config.publickeyEndpoint = Config.api_bridgeEndpoint + '/user/pkey/' // {{br_api_ur}}/user/pkey/{{user_id}}
		Config.savepatientEndpoint = Config.api_bridgeEndpoint + '/user/selfreg'
		Config.tinChecker = Config.api_bridgeEndpoint + '/user/tinChecker'
		Config.bridgeCheckUuid = Config.api_bridgeEndpoint + '/user/checkuuid'
		Config.countriesEndpoint = Config.api_bridgeEndpoint + '/countries'
	}
}
