import { Component, OnInit, AfterViewInit, ViewChildren, QueryList } from '@angular/core'
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { SessionService } from 'src/app/services/session.service'
import { Router } from '@angular/router'

import { NgOtpInputComponent } from 'ng-otp-input'
import { CdTimerComponent } from 'angular-cd-timer'

import { Address } from 'src/app/models/address.model'
import { stateType } from '../message-modal/message.modal'
import { MessageModal } from '../message-modal/message.modal'

import { faCircleCheck, faCircleXmark, faXmark } from '@fortawesome/free-solid-svg-icons'
import { TranslateService } from '@ngx-translate/core'
import { TokenResponse, User } from 'src/app/models/user.model'

@Component({
	selector: 'otp-modal',
	templateUrl: './otp.modal.html',
	styleUrls: ['./otp.modal.scss'],
	providers: [NgbModal],
})
export class OtpModal implements OnInit, AfterViewInit {
	@ViewChildren('ngOtpInput') ngOtpInput: QueryList<NgOtpInputComponent>
	@ViewChildren('timer') timer: QueryList<CdTimerComponent>

	show: boolean

	country: string //deve arrivare da chi lo chiama

	otpCodeError: boolean
	otpCode: string
	otpResendDisabled: boolean
	isReSendingOtpCode: boolean
	otpMessageID: string
	otpExpiringInSec: number
	isSendingOtpCode: boolean
	otpViaEmail:boolean
	otpEmail: string
	isOptician: boolean
	
	action: string

	currentModal

	formattedOtpPhone: string

	faXmark = faXmark

	constructor(
		public session: SessionService,
		private activeModal: NgbActiveModal,
		public translator: TranslateService,
		private modalService: NgbModal,
		private router: Router
	) {
		this.show = false

		this.country = '' // deve arrivare dal chiamante

		this.otpCodeError = false
		this.otpCode = ''
		this.otpResendDisabled = true
		this.isReSendingOtpCode = false
		this.otpMessageID = ''
		this.otpExpiringInSec = 0
		this.isSendingOtpCode = false
		this.action = 'selfreg'

		this.formattedOtpPhone = '' // deve arrivare dal chiamante
		this.otpViaEmail = false
		this.otpEmail = ''	
		this.isOptician = true
	}

	ngOnInit(): void {}

	ngAfterViewInit(): void {
		this.show = true
		// console.log(this.country)
		// console.log(this.formattedOtpPhone)
	}

	onOtpChange(otpCode: string) {
		this.otpCode = otpCode
		this.otpCodeError = false
	}

	onOtpResendClick() {
		this.ngOtpInput.first.setValue('')
		this.otpResendDisabled = true
		this.isReSendingOtpCode = true

		const param = this.otpViaEmail
			? { action: this.action, lang: this.country, phoneNumber: '', isEmail: 'Y', email: this.otpEmail }
			: { action: this.action, lang: this.country, phoneNumber: this.formattedOtpPhone, isEmail: 'N', email: '' }

		this.session
			.otpInit(param)
			.then((res) => {
				if (res.messageID) {
					this.otpMessageID = res.messageID
				}
				if (res.expiringInSec) {
					this.otpExpiringInSec = res.expiringInSec
				}
			})
			.catch(() => {
				// this.otpInternalError = true
				let text = [this.translator.instant('MODAL.INTERNAL_ERROR')]
				this.openMessage(text, stateType.FAIL)
			})
			.finally(() => {
				this.timer.first.startTime = this.otpExpiringInSec
				this.timer.first.start()
				this.isReSendingOtpCode = false
			})
	}

	onOtpSubmit() {
		this.isSendingOtpCode = true

		this.session
			.otpVerify({ messageID: this.otpMessageID, otp: this.otpCode })
			.then((resp: TokenResponse) => {
				// console.log(resp)
				if (resp) {
					this.activeModal.close(resp)
					// this.session.savenewuser(this.draftUser, this.mainAddr, res).then((resp)=>{

					// 	let text = [this.translator.instant("CREATE_USER.MODAL_TRUE_BODY"), this.translator.instant("CREATE_USER.MODAL_TRUE_BODY_SECOND-PART")]
					// 	this.openMessage(text, stateType.SUCCESS )

					// }).catch((err)=>{

					// 	let text = [this.translator.instant("CREATE_USER.INTERNAL_ERROR")]
					// 	this.openMessage(text, stateType.FAIL )

					// })
				}
			})
			.catch((e) => {
				// this.activeModal.dismiss()
				if (e.status === 400) {
					this.otpCodeError = true
				} else {
					// this.otpInternalError = true
					let text = [this.translator.instant('MODAL.INTERNAL_ERROR')]
					this.openMessage(text, stateType.FAIL)
				}
			})
			.finally(() => {
				this.isSendingOtpCode = false
			})
	}

	onCompleteOtpTimer() {
		this.otpResendDisabled = false
	}

	public closeOtpModal() {
		// this.activeModal.close(false)
		this.activeModal.dismiss()
	}

	private openMessage(text: string[], state: stateType) {
		this.currentModal = this.modalService.open(MessageModal, { size: 'l', keyboard: false, backdrop: 'static' })
		this.currentModal.componentInstance.state = state
		this.currentModal.componentInstance.texts = text

		this.currentModal.result.then(
			(confirmed) => {
				this.session.redirect()
			},
			(reason) => {
				window.location.reload()
			}
		)
	}
}
