<div class="panel-modal" [ngClass]="show ? 'show': ''  ">
	<div class="container-fluid">
		<div class="row">
			<div class="col-12 d-flex flex-column align-items-center">
				<div>
					<fa-icon [icon]="faCircleCheck" faCircleCheck aria-hidden="true" class="circleCheck pt-3 text-center fa-6x" *ngIf="state == 0"></fa-icon>

					<fa-icon [icon]="faCircleXmark" faCircleXmark aria-hidden="true" class="errorIcon pt-3 fa-6x" *ngIf="state == 1"></fa-icon>

					<fa-icon [icon]="faTriangleExclamation" faTriangleExclamation aria-hidden="true" class="errorIcon pt-3 fa-6x" *ngIf="state == 2"></fa-icon>
				</div>

				<div class="text-center">
					<h5 class="modal-title fw-bold" *ngIf="state == 0">{{ "MODAL.MODAL_TRUE_TITLE_CREATE" | translate }}</h5>

					<h5 class="modal-title fw-bold" *ngIf="state == 1">{{ "MODAL.MODAL_FALSE_TITLE" | translate }}</h5>

					<h5 class="modal-title fw-bold" *ngIf="state == 2">{{ "MODAL.ERROR" | translate }}</h5>
				</div>
				<div>
					<div class="modal-body text-center">
						<div *ngFor="let text of texts">
							<p [innerText]="text"></p>
						</div>
					</div>
				</div>

				<div class="button-container">
					<button (click)="go()" type="button" class="btn btn-danger" *ngIf="state == 0 && !isPatientAutoReg">{{ "BUTTONS.MODAL_TRUE_BUTTON" | translate }}</button>

					<button (click)="close()" type="button" class="btn btn-secondary" *ngIf="state == 1">{{ "BUTTONS.EXIT" | translate }}</button>
				</div>
			</div>
		</div>
	</div>
</div>
