import { Inject, Injectable, inject } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Observable } from 'rxjs'
import { SessionService } from './session.service'

// old method with CanActivate that is deprecated, works

// @Injectable({
// 	providedIn: 'root',
// })

// export class AuthGuard implements CanActivate {
// 	// session: SessionService = inject(SessionService)
// 	// router: Router = inject(Router)
// 	constructor(private session: SessionService, private router: Router) {
// 		console.log('pippo')
// 	}

// 	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
// 		return this.session.patientAutoregAvailable().then((auth: boolean) => {
// 			console.log('pippo')
// 			if (auth) {
// 				return true
// 			} else {
// 				this.router.navigate(['/'])
// 				return false
// 			}
// 		})
// 	}
// }

//new method with CanActivateFn

export const AuthGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
	const session = inject(SessionService)
	const router = inject(Router)

	return session.patientAutoregAvailable().then((auth: boolean) => {
		if (auth) {
			return true
		} else {
			router.navigate(['/'])
			return false
		}
	})
}
