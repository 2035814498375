<div class="row d-flex justify-content-center">
	<div class="col-md-10 col-xl-10 col-xxl-8 col-12 position-relative p-xs-0">
		<img class="logo" src="assets/images/logo.png" alt="Visionix" />
		<div class="modal-container">
			<div class="text-center my-2">
				<h4 class="panel-title fw-bold text-uppercase">{{ "CREATE_USER.PAGE_TITLE" | translate }}</h4>
			</div>

			<form name="createUserForm" #createUserForm="ngForm" id="createUserForm" #UserForm validate (ngSubmit)="onSubmit(createUserForm.invalid)">
				<div class="row">
					<div class="col-12">
						<div class="row">
							<div class="form-group col-12 col-md-6 position-relative">
								<label for="firstname">{{ "CREATE_USER.FIRSTNAME" | translate }} </label>
								<input type="text" maxlength="100" minlength="3" class="form-control" name="firstname" id="firstname" placeholder="{{ 'CREATE_USER.FIRSTNAME' | translate }}" [ngClass]="{ 'has-error': fn.touched && !fn.valid }" [(ngModel)]="draftUser.firstname" #fn="ngModel" required />
								<span class="error-message" [hidden]="!fn.invalid">{{ "MESSAGE.REQUIRED" | translate }}</span>
							</div>

							<div class="form-group col-12 col-md-6 position-relative">
								<label for="lastname">{{ "CREATE_USER.LASTNAME" | translate }} </label>
								<input type="text" maxlength="100" minlength="3" class="form-control" name="lastname" id="lastname" placeholder="{{'CREATE_USER.LASTNAME' | translate }}" [ngClass]="{ 'has-error': ln.touched && !ln.valid }" [(ngModel)]="draftUser.lastname" #ln="ngModel" required />
								<span class="error-message" [hidden]="!ln.invalid">{{ "MESSAGE.REQUIRED" | translate }}</span>
							</div>
						</div>

						<div class="row">
							<div class="form-group col-12 col-md-6 position-relative">
								<label for="ref_email">{{ "CREATE_USER.REF_EMAIL" | translate }}</label>
								<input
									type="email"
									pattern='^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
									class="form-control normalcase"
									name="ref_email"
									id="ref_email"
									placeholder="{{'CREATE_USER.REF_EMAIL' | translate }}"
									(keyup)="ErrMsgEmail($event)"
									[(ngModel)]="mainAddr.ref_email"
									#email="ngModel"
									required />
								<span class="invalid-email" [hidden]="!emailError">{{ "CREATE_USER.EMAIL_ERROR" | translate }}</span>
								<span class="error-message" [hidden]="!email.pristine">{{ "MESSAGE.REQUIRED" | translate }}</span>
							</div>

							<div class="form-group col-12 col-md-6 position-relative">
								<label for="phone">{{ "CREATE_USER.PHONE" | translate }}</label>
								<input type="text" maxlength="100" class="form-control" name="phone" id="phone" placeholder="{{ 'CREATE_USER.PHONE_HINT' | translate }}" [(ngModel)]="mainAddr.phone" />
							</div>
						</div>

						<div class="row">
							<div class="form-group col-12 col-md-6 position-relative">
								<label for="country">{{ "CREATE_USER.COUNTRY" | translate }} </label>
								<searchdropdown *ngIf="countries != null && countries.length > 0" class="normalcase" style="padding: 0" [countries]="countries" #searchCountry (outcountry)="selCountry($event)" (cancelCountry)="resetCountry($event)" aria-required="true" required> </searchdropdown>
								<span class="error-message" [hidden]="mainAddr.country != '' ">{{ "MESSAGE.REQUIRED" | translate }}</span>
							</div>

							<div class="form-group col-12 col-md-6 position-relative" *ngIf="!disableVat">
								<label for="vat">{{ "CREATE_USER.VAT" | translate }} </label>
								<div class="vat-input-container">
									<div class="country-code">
										<p *ngIf="mainAddr.country">{{countryIso2.toUpperCase()}}</p>
									</div>
									<input
										type="text"
										maxlength="100"
										class="form-control normalcase vat"
										id="vat"
										name="vat"
										placeholder="{{'CREATE_USER.VAT' | translate }}"
										[ngClass]="{ 'has-error': vatError }"
										[(ngModel)]="draftUser.vat"
										(keyup)="onVatChange($event)"
										#vat="ngModel"
										[disabled]="isFetchingUserDataFromVat"
										required />
								</div>
								<span class="error-message" [hidden]="!vat.invalid">{{ "MESSAGE.REQUIRED" | translate }}</span>
								<span class="error-message" [hidden]="!(mainAddr.country && vatError) || internalVatCheckerError || isFetchingUserDataFromVat"> {{ "CREATE_USER.VAT_ERROR" | translate }} </span>
								<span class="error-message" [hidden]="!internalVatCheckerError || isFetchingUserDataFromVat">{{ "CREATE_USER.INTERNAL_ERROR" | translate }}</span>
								<div [hidden]="!(mainAddr.country && isFetchingUserDataFromVat)" class="info-message">
									<div class="spinner-border spinner-border-sm text-primary" role="status">
										<span class="sr-only">Loading...</span>
									</div>
									<p>{{ "CREATE_USER.VAT_EXISTENCE_CHECK" | translate }}</p>
								</div>
							</div>
							<!-- </div> -->

							<!-- <div class="row"> -->
							<div class="form-group col-12 col-md-6 position-relative">
								<label for="address">{{ "CREATE_USER.ADDRESS" | translate }} </label>
								<input
									(input)="checkControlForDashes(addr)"
									type="text"
									maxlength="100"
									class="form-control normalcase"
									name="address"
									id="address"
									placeholder="{{'CREATE_USER.ADDRESS' | translate }}"
									[ngClass]="{ 'has-error': addr.touched && !addr.valid }"
									[(ngModel)]="mainAddr.address_line1"
									#addr="ngModel"
									[disabled]="isFetchingUserDataFromVat"
									required />
								<span class="error-message" [hidden]="!addr.invalid">{{ "MESSAGE.REQUIRED" | translate }}</span>
							</div>

							<div class="form-group col-12 col-md-6 position-relative">
								<label for="city">{{ "CREATE_USER.CITY" | translate }} </label>
								<input
									(input)="checkControlForDashes(city)"
									type="text"
									maxlength="100"
									class="form-control normalcase"
									id="city"
									name="city"
									placeholder="{{ 'CREATE_USER.CITY' | translate }}"
									[ngClass]="{ 'has-error': city.touched && !city.valid }"
									[(ngModel)]="mainAddr.city"
									#city="ngModel"
									[disabled]="isFetchingUserDataFromVat"
									required />
								<span class="error-message" [hidden]="!city.invalid">{{ "MESSAGE.REQUIRED" | translate }}</span>
							</div>
							<!-- </div>

							<div class="row"> -->
							<div class="form-group col-12 col-md-6 position-relative">
								<label for="zip">{{ "CREATE_USER.ZIP" | translate }} </label>
								<input type="text" class="form-control" name="zip" id="zip" placeholder="{{ 'CREATE_USER.ZIP' | translate }}" [ngClass]="{ 'ng-invalid': zipCodeError }" (keyup)="checkZipCode(mainAddr.zip)" [(ngModel)]="mainAddr.zip" #zip="ngModel" [disabled]="isFetchingUserDataFromVat" required />
								<span class="error-message" [hidden]="!zip.invalid && !zipCodeError">{{ "CREATE_USER.ZIPCODE_ERROR" | translate }}</span>
							</div>

							<div class="form-group col-12 col-md-6 position-relative">
								<label for="province">{{ "CREATE_USER.PROVINCE" | translate }} </label>
								<input
									(input)="checkControlForDashes(pr)"
									type="text"
									maxlength="100"
									class="form-control normalcase"
									id="province"
									name="province"
									placeholder="{{ 'CREATE_USER.PROVINCE_HINT' | translate }}"
									[ngClass]="{ 'has-error': pr.touched && !pr.valid }"
									[(ngModel)]="mainAddr.province"
									#pr="ngModel"
									[disabled]="isFetchingUserDataFromVat"
									required />
								<span class="error-message" [hidden]="!pr.invalid">{{ "MESSAGE.REQUIRED" | translate }}</span>
							</div>
							<!-- </div> -->
							<div class="form-group col-12 col-md-6 position-relative">
								<label for="group">{{ "CREATE_USER.ORGANIZATION" | translate }}</label>
								<input
									(input)="checkControlForDashes(org)"
									type="text"
									maxlength="100"
									minlength="3"
									class="form-control normalcase"
									name="organization"
									id="organization"
									[(ngModel)]="mainAddr.organization"
									#org="ngModel"
									placeholder="{{'CREATE_USER.ORGANIZATION_HINT' | translate }}"
									[disabled]="isFetchingUserDataFromVat"
									[required]="draftUser.role == 'Optician'" />
								<span class="error-message" [hidden]="org.valid">{{ "MESSAGE.REQUIRED" | translate }}</span>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-12 form-group d-flex align-items-center">
							<div class="form-check form-switch">
								<input class="form-check-input form-control" type="checkbox" name="flexSwitchCheckDefault" id="flexSwitchCheckDefault" [(ngModel)]="draftUser.privacyP" [disabled]="toggle" required />
							</div>
							<label class="form-check-label ms-2">
								{{ "CREATE_USER.TERMS" | translate }}

								<a (click)="getTerms()" class="link-dark" style="cursor: pointer"> {{ "CREATE_USER.TERMS_2" | translate }} </a>
							</label>
						</div>
					</div>
					<div class="row mt-3" *ngIf="mainAddr.country">
						<!-- PHONE OTP -->
						<div class="col-12" *ngIf="!otpViaEmail">
							<p class="my-3">{{ "CREATE_USER.OTP_PHONE_INSTRUCTIONS" | translate }}</p>

							<div class="row">
								<div class="form-group col-12 col-md-6 position-relative">
									<label for="otpPhone">{{ "CREATE_USER.OTP_PHONE" | translate }}</label>
									<div class="input-group">
										<span class="input-group-text" id="phone-addon" *ngIf="mainAddr.country != '' ">{{countryDialCode}}</span>
										<input type="number" class="form-control otpPhone" maxlength="20" name="otpPhone" id="otpPhone" placeholder="{{ 'CREATE_USER.OTP_PHONE' | translate }}" [ngClass]="{ 'has-error': otpPhoneError }" [(ngModel)]="otpPhone" #otphone="ngModel" (keyup)="onOtpPhoneChange($event)" required />
										<span class="error-message" [hidden]="!otpPhoneError && !otphone.invalid">{{ "CREATE_USER.OTP_PHONE_ERROR" | translate }}</span>
									</div>
								</div>
								<div class="form-group col-12 col-md-6 d-flex align-items-end">
									<button type="submit" class="btn btn-danger d-flex justify-content-center align-items-center" [disabled]="createUserForm.invalid || zipCodeError || vatError || internalVatCheckerError || otpServiceLoading || otpPhoneError">
										<span *ngIf="!otpServiceLoading">{{ "BUTTONS.SEND_OTP_CODE" | translate }}</span>
										<div class="spinner-border spinner-border-sm" role="status" *ngIf="otpServiceLoading">
											<span class="sr-only">Loading...</span>
										</div>
									</button>
									<!-- <p class="error-message" [hidden]="!otpInternalError">{{ "CREATE_USER.INTERNAL_ERROR" | translate }}</p> -->
								</div>
							</div>
						</div>

						<!-- EMAIL OTP -->
						<div class="col-12" *ngIf="otpViaEmail">
							<p class="my-3">{{ "CREATE_USER.OTP_MAIL_INSTRUCTIONS" | translate }}</p>

							<div class="row">
								<div class="form-group col-12 col-md-6 position-relative">
									<label for="otp_email">{{ "CREATE_USER.OTP_EMAIL" | translate }}</label>
									<input
										type="email"
										pattern='^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
										class="form-control normalcase"
										name="otp_email"
										id="otp_email"
										placeholder="{{'CREATE_USER.OTP_EMAIL' | translate }}"
										(keyup)="ErrMsgEmail($event)"
										[(ngModel)]="otpEmail"
										#otpemail="ngModel"
										required />
									<span class="invalid-email" [hidden]="!otpEmailError">{{ "CREATE_USER.EMAIL_ERROR" | translate }}</span>
									<span class="error-message" [hidden]="!otpemail.pristine">{{ "MESSAGE.REQUIRED" | translate }}</span>
								</div>
								<div class="form-group col-12 col-md-6 d-flex align-items-end">
									<button type="submit" class="btn btn-danger d-flex justify-content-center align-items-center" [disabled]="createUserForm.invalid || zipCodeError || vatError || internalVatCheckerError || otpServiceLoading || otpPhoneError">
										<span *ngIf="!otpServiceLoading">{{ "BUTTONS.SEND_OTP_CODE" | translate }}</span>
										<div class="spinner-border spinner-border-sm" role="status" *ngIf="otpServiceLoading">
											<span class="sr-only">Loading...</span>
										</div>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
