import { Component, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

import { SessionService } from 'src/app/services/session.service'
import { FormsModule } from '@angular/forms'
// import { DataModelService } from '../../services/data-model.service';
import { Config } from '../../../config'
import { User } from '../../models/user.model'
import { Util } from '../../models/util.model'
import { Preferences } from '../../models/settings.model'
import { MessageModal } from 'src/app/elements/message-modal/message.modal'
import { stateType } from 'src/app/elements/message-modal/message.modal'

import { faInfoCircle, faTriangleExclamation, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { faSave } from '@fortawesome/free-regular-svg-icons'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Router } from '@angular/router'

@Component({
	selector: 'profile',
	templateUrl: './profile.component.html',
	styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
	edits: {
		firstName?: string
		lastName?: string
		email?: string
		phone?: string
		password?: string
		organization?: string

		address?: string // 31.05.2017 add
		city?: string
		province?: string
		zip?: string
		country?: string
		order_reg_num?: string // solo per doctors e specialists
	}

	// 10.03.2022 non piu' modal, torno qui
	// su pwd.modal

	disclaimer: string

	profile: {
		old_password: string
		new_password: string
		confirm_password: string
		changePwdError: string
	}

	prefs: Preferences // 10.03.2022

	validPwd: boolean

	//tooltipMsg : string;

	// modal; ora modalService
	currentModal
	currUser: User // 07.03.2019
	currentAction: string

	// 10.03.2022 gestione con tabs
	activeTab: string
	enabledSaveButton: boolean

	enableContractTab: boolean

	static PDF_FORMATS = Config.PDF_FORMATS
	static DATE_FORMATS = Config.DATE_FORMATS

	//filter: any;  // 24.10.2018 per leggere da json

	hasReports: boolean
	// per warning su ref ? non dipende da lui ?
	flagRef: string

	faInfoCircle = faInfoCircle // 22.03.2022
	faSave = faSave // per usarla su html
	faTriangleExclamation = faTriangleExclamation
	faCircleCheck = faCircleCheck
	faCircleXmark = faCircleXmark

	// calcolate date
	year: number
	month: number
	computedMessage: string
	yearsOptions: number[]

	attentionBody: string

	constructor(
		public session: SessionService,
		public modalService: NgbModal,
		private translator: TranslateService,
		private router : Router
	) {
		Util.debug('[profile] - constructor')
		
		this.year = null
		this.month = null
		this.computedMessage = ''
		this.yearsOptions = []

		this.edits = {}
		this.currentAction = 'editProfile'

		this.initProfileData()
		this.initPwdTab()
		this.initPrefs()

		this.hasReports = false

		this.attentionBody = this.translator.instant('PROFILE.MANDATORY_MAIL')
	}

	ngOnInit(): void {
		Util.debug('[profile] - ngOnInit')
	}

	private initProfileData() {
		this.edits = {}
		this.edits.firstName = this.session.getFirstName()
		this.edits.lastName = this.session.getLastName()
		this.edits.email = this.session.getEmail()
		this.edits.phone = this.session.getPhone()
		this.edits.organization = this.session.getOrganization()
		this.currUser = this.session.getCurrentUser()
		if (this.currUser.order_reg_num) this.edits.order_reg_num = this.currUser.order_reg_num // 11.02.2021
		this.edits.city = this.currUser.mainAddress.city
		this.edits.province = this.currUser.mainAddress.province
		this.edits.zip = this.currUser.mainAddress.zip
		this.edits.country = this.currUser.getCountry() // mainAddress.country;  // 06.09.2022 cmq readonly
		this.edits.address = this.currUser.mainAddress.address_line1
	}

	initPwdTab() {
		this.validPwd = false
		this.profile = {
			old_password: '',
			new_password: '',
			confirm_password: '',
			changePwdError: '',
		}

		// 17.06.2022 spostare su html ?
		// 07.03.2019 inizializza, poi semmai cambia in base al brand
		this.disclaimer = this.translator.instant('PROFILE.PASSWORD_DISCLAIMER')
	}

	initPrefs() {
		this.prefs = {
			pdf_format: this.currUser.settings.pdf_format,
			date_format: this.currUser.settings.date_format,
			lang: this.currUser.settings.lang,
		}
	}

	editProfile(form?) {
		Util.debug('(editProfile) valid form? ' + (form != null))
		{
			//don't update country, keep the one already inside the profile
			this.edits.country = null
			this.session
				.updateProfile(this.edits)
				.then(() => {
					// var okMsg = this.translator.instant('MESSAGE.OK_SAVED');
					// alert(okMsg);
					this.session.savenewuser(this.edits).then((resp)=>{
						console.log(resp)

						let text = [this.translator.instant("MODAL.MODAL_TRUE_BODY_SECOND-PART_USER")]
						this.openMessage(text, stateType.SUCCESS )

					}).catch((err)=>{
						console.log(err)
					})
				})
				.catch((err) => {
					console.log(err)

					let text = [this.translator.instant("MODAL.MODAL_FALSE_BODY" )] 
					this.openMessage(text, stateType.FAIL )

					return false
				})
		}
	}


	private openMessage(text : string[], state:stateType ){

		this.currentModal = this.modalService.open(MessageModal, { size: 'l', keyboard: false, backdrop: 'static' })
		this.currentModal.componentInstance.state = state
		this.currentModal.componentInstance.texts = text

		this.currentModal.result.then(
			(confirmed) => {
				this.session.redirect()
			},
			(reason) => {
				this.router.navigate([''])
			}
		)
	}
}
