import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core'
import { Config } from 'src/config'
import { Util } from 'src/app/models/util.model'

import { SessionService } from 'src/app/services/session.service'
import { Patient, PatientObj, activeTab } from 'src/app/models/patient.model'

import { Anamnesis } from 'src/app/models/anamnesis.model'

import { Subscription } from 'rxjs'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { MessageModal, stateType } from 'src/app/elements/message-modal/message.modal'
// import { ActivatedRoute, Router, UrlTree } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { Agreeement } from 'src/app/models/agreements.model'
import { CryptoUtilsService } from 'src/app/services/crypto-utils.service'
import { Router } from '@angular/router'

@Component({
	selector: 'app-create-patient',
	templateUrl: './create-patient.component.html',
	styleUrls: ['./create-patient.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class CreatePatientComponent implements OnInit, OnDestroy {
	patient: Patient
	tabsDescription: string[]
	tabs: activeTab[]

	isLoading: boolean

	patientCreation: Subscription

	currentModal

	constructor(
		public session: SessionService,
		private modalService: NgbModal,
		private translator: TranslateService,
		private cryptoUtils: CryptoUtilsService,
		private router: Router
	) {
		Util.debug('Constructor - CreatePatientComponent')

		this.isLoading = true

		this.tabsDescription = [Config.ANAGTAB, Config.ANAMTAB, Config.AGREMTAB]
		this.tabs = []

		this.initTabs()

		this.session
			.checkUUIDValidity()
			.then((resp) => {
				// console.log(resp)
				let patient = new Patient()
				this.session.setPatient(patient)
				this.isLoading = false
				this.tabs[0].active = true //prima tab attiva
			})
			.catch(() => {
				this.isLoading = false
				this.openMessage([this.translator.instant('MODAL.UUID_ERROR'), this.translator.instant('MODAL.UUID_ERROR_SUGGESTION')], stateType.WARNING)
			})

		// console.log(this.tabs)
		// console.log(this.tabs.slice(-1))
	}

	ngOnInit(): void {
		// subscribe, ogni volta che verrá cliccato su next, sono in ascolto e cambio pagina di conseguenza
		this.patientCreation = this.session.createPatient.subscribe((patObj: PatientObj) => {
			// console.log(patObj)
			this.isLoading = true
			this.session.setPatient(patObj.patient)

			if (patObj.id == 0) {
				this.initPatient()
					.then(() => {
						this.isLoading = false
						this.changeTab(patObj.id) //cambio tab
					})
					.catch(() => {
						this.openMessage([this.translator.instant('MODAL.INTERNAL_ERROR')], stateType.FAIL)
					})
			} else {
				if (patObj.id != this.tabs.slice(-1)[0].id) {
					//se non é l'ultima tab
					this.isLoading = false
					this.changeTab(patObj.id)
				} else {
					// gestito il caso dei gruppi
					let id = 0
					if (this.patient.group_id > 0) {
						id = this.patient.group_id
					} else {
						id = this.patient.owner_id
					}

					this.session
						.loadOpticianPKey(id)
						.then((pKey) => {
							// console.log(pKey)
							let patientPrivData = JSON.stringify(this.patient.getPatientPrivData())
							// console.log(patientPrivData)

							this.cryptoUtils
								.encryptDataRSAES(patientPrivData, pKey)
								.then((resp: string) => {
									// console.log(resp)
									this.patient.priv_data = resp
									this.session.setPatient(this.patient)

									this.session
										.saveNewPatient()
										.then((resp) => {
											this.isLoading = false

											let message = this.translator.instant('MODAL.PATIENT_REGISTERED')

											this.openMessage([message], stateType.SUCCESS)

											this.changeTab(patObj.id)

											this.tabs[0].active = false // finita la registrazione non volgio si veda sotto il form, forse potrei non chiamare direttamente il changeTab
										})
										.catch((err) => {
											console.log(err)
											this.openMessage([this.translator.instant('MODAL.INTERNAL_ERROR')], stateType.FAIL)
										})
								})
								.catch((err) => {
									console.log(err)
									this.openMessage([this.translator.instant('MODAL.INTERNAL_ERROR'), err], stateType.FAIL)
								})
						})
						.catch((err) => {
							this.openMessage([this.translator.instant('MODAL.INTERNAL_ERROR')], stateType.FAIL)
						})
				}
			}
		})
	}

	changeTab(tabId: number, ignoreTabLogic = false) {
		setTimeout(() => {
			// timeout per l'effetto dissolvenza
			if (ignoreTabLogic) {
				this.tabs.forEach((tab) => {
					tab.active = false

					if (tab.id == tabId) {
						tab.active = true
					}
				})
			} else {
				if (tabId != this.tabs.slice(-1)[0].id) {
					// se non uguale all'ul;timo elem dell'array quindi ultima tab
					let prevTab = this.tabs.filter((el) => el.id == tabId)
					prevTab[0].active = false
					prevTab[0].complete = true

					tabId++
					let nextTab = this.tabs.filter((el) => el.id == tabId)
					nextTab[0].active = true
					nextTab[0].complete = false
				} else {
					// ritorno alla prima tab e resetto il paziente
					let patient = new Patient()

					this.session.setPatient(patient)

					for (const tab of this.tabs) {
						tab.active = false
						tab.complete = false

						if (tab.id == 0) {
							tab.active = true
						}
					}
				}
			}
		}, 300)
	}

	private initPatient(): Promise<any> {
		return Promise.all([this.loadPatAnamnesi(), this.loadPatAgreement()]).then((resp) => {
			// console.log(resp)
			let anamnesi: Anamnesis[] = resp[0]
			let agreement: Agreeement = resp[1]

			this.setPatient(anamnesi, agreement)
			// this.isLoading = false
		})
	}

	private loadPatAnamnesi(): Promise<Anamnesis[]> {
		const promise = new Promise<Anamnesis[]>((resolve, reject) => {
			this.session
				.loadPatientAnamnesi()
				.then((resp) => {
					// console.log(resp)
					resolve(resp)
				})
				.catch((err) => {
					console.log(err)
					// this.openMessage([err.message], stateType.FAIL)
					reject()
				})
		})

		return promise
	}

	private loadPatAgreement(): Promise<Agreeement> {
		const promise = new Promise<Agreeement>((resolve, reject) => {
			this.session
				.loadPatientAgreemnt()
				.then((resp) => {
					resolve(resp[0])
				})
				.catch((err) => {
					console.log(err)
					// this.openMessage([err.message], stateType.FAIL)
					reject()
				})
		})

		return promise
	}

	private initTabs() {
		Util.debug('CreatePatientComponent - initTabs')
		for (let i = 0; i < this.tabsDescription.length; i++) {
			const desc = this.tabsDescription[i]

			let tab: activeTab
			tab = new activeTab()
			tab.description = desc
			tab.id = i
			this.tabs.push(tab)
		}
		// console.log(this.tabs)
		// this.isLoading = false
	}

	private setPatient(anam: Anamnesis[], agreement: Agreeement) {
		this.patient = this.session.getPatient()
		this.patient.anamnesi = anam
		this.patient.agreement = agreement

		this.patient.owner_id = this.session.getpc_userId()
		this.patient.group_id = this.session.getpc_groupId()
		this.patient.uuid = this.session.getpc_uuid()

		this.patient.setUniqueHash(this.cryptoUtils)

		this.session.setPatient(this.patient)
	}

	private openMessage(text: string[], state: stateType) {
		this.currentModal = this.modalService.open(MessageModal, { size: 'l', keyboard: false, backdrop: 'static' })
		this.currentModal.componentInstance.state = state
		this.currentModal.componentInstance.texts = text

		this.currentModal.result.then(
			(confirmed) => {
				console.log('confirmed')
			},
			(reason) => {
				console.log('reason')
				window.location.reload() // ricarico da zero la pagina
			}
		)
	}

	ngOnDestroy(): void {
		this.patientCreation.unsubscribe()
	}
}
