<div class="normalcase zero-padding position-relative">
	<a class="reset-button" [ngClass]="{ hide: !countrySelected }" (click)="resetCountry()"><fa-icon [icon]="faXmark"></fa-icon></a>

	<input
		type="text"
		class="form-control normalcase"
		[ngClass]="{ 'has-error': !countrySelected }"
		(click)="visible = !visible"
		name="drpcountry"
		placeholder="{{ 'CREATE_USER.COUNTRY_HINT' | translate }}"
		[(ngModel)]="drpcountry"
		(keyup)="filter()"
		#country
		#state="ngModel"
		[disabled]="countrySelected"
		autocomplete="no-autocomplete"
		(blur)="onBlur()"
		required />
	<span class="error-message" [hidden]="!(state.invalid && state.touched)">{{ "MESSAGE.REQUIRED" | translate }}</span>
	<fa-icon [icon]="faCheck" faCheck [ngClass]="{ hide: !countrySelected }"></fa-icon>
</div>
<div class="drp-fixed" (lostfocus)="visible = false" [ngClass]="{ 'z-index999': !visible }">
	<ul role="listbox" aria-expanded="false" [hidden]="!visible">
		<li *ngFor="let country of dataFiltered; let i = index" [attr.data-index]="i">
			<div #listCountries [ngClass]="{ selected: currentValue === country }" (mousedown)="select(country)">
				<span class="fi fi-{{ country.alpha2.toLowerCase() }}" tabindex="0"></span>
				<span tabindex="0" [innerHtml]="country.name"></span>
				<!-- [ngClass]="{selected: currentValue === country}" -->
			</div>
		</li>
	</ul>
</div>
