import { Injectable } from '@angular/core'

import { HttpClient } from '@angular/common/http'
import { TranslateService } from '@ngx-translate/core'

import { CryptoUtilsService } from '../services/crypto-utils.service'

import { Util } from '../models/util.model'

export enum DataStatus {
	NOT_LOADED,
	LOADING,
	LOADED,
	LOAD_FAILED,
}

@Injectable({
	providedIn: 'root',
})
export class DataModelService {
	constructor(
		private http: HttpClient,
		private translator: TranslateService // 14.04.2022 to decode block icd
	) {
		console.log('(DM costruttore) ')
		// this.resetAll()
	}

	// *********************************************************************

	// 22.11.2021
	myGet(request): Promise<any> {
		let promise = new Promise((resolve, reject) => {
			Util.debug('(DM) [myGet] calling: ' + request.url)
			this.http.get<any>(request.url, { headers: request.headers }).subscribe({
				next: (res) => {
					resolve(res)
				},
				error: (err) => {
					reject(err)
				},
				complete: () => {
					// console.log('complete');
				},
			})
		})
		return promise
	}

	// 22.11.2021
	myPost(request, message): Promise<any> {
		let promise = new Promise((resolve, reject) => {
			Util.debug('(DM) [myPost] calling: ' + request.url)
			this.http.post<any>(request.url, message, { headers: request.headers }).subscribe({
				next: (res) => {
					resolve(res)
				},
				error: (err) => {
					reject(err)
				},
				complete: () => {
					// console.log('complete');
				},
			})
		})
		return promise
	}

	// 29.11.2021
	myPut(request, message): Promise<any> {
		let promise = new Promise((resolve, reject) => {
			Util.debug('(DM) [myPut] calling: ' + request.url)
			this.http.put<any>(request.url, message, { headers: request.headers }).subscribe({
				next: (res) => {
					resolve(res)
				},
				error: (err) => {
					reject(err)
				},
				complete: () => {
					// console.log('complete');
				},
			})
		})
		return promise
	}

	// 30.11.2021
	myDelete(request): Promise<any> {
		let promise = new Promise((resolve, reject) => {
			Util.debug('(DM) [myDel] calling: ' + request.url)
			this.http.delete<any>(request.url, { headers: request.headers }).subscribe({
				next: (res) => {
					resolve(res)
				},
				error: (err) => {
					reject(err)
				},
				complete: () => {
					// console.log('complete');
				},
			})
		})
		return promise
	}
}
